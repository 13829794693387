<template>
  <div class="examinationpaper wrongtopic clearfix">
    <zv-nav-bar :title="pagename" v-if="!isShow"> </zv-nav-bar>
    <div
      class="paperbox"
      v-if="!isShow"
      :style="{ 'margin-bottom': isiOS ? '0.8rem' : '0' }"
      :class="{ 'paperbox--tongue': isTongue && !isTongue, 'paperbox--tongue-ios': isiOS && isTongue }"
    >
      <div
        class="video-box"
        v-if="
          questionlist[activeIndex] &&
          questionlist[activeIndex].type.indexOf('Tongue') > -1 &&
          tongueMediaSrcData.videoUrl !== ''
        "
      >
        <ali-player
          ref="ali-player"
          :videoSrc="tongueMediaSrcData.videoUrl"
          :playVideoCallback="initTemplateAudio"
          :videoCover="questionlist[activeIndex].videoCover"
          v-if="tongueMediaSrcData.videoUrl"
        />
      </div>

      <div class="testbox">
        <template v-for="(q, q_index) in questionlist">
          <template v-if="q_index === activeIndex">
            <div
              class="title"
              :style="{
                'margin-bottom':
                  q.type === 'GroupOption' || q.type === 'GroupStem' || q.type === 'CaseAnalysis' || q.type === 'Tongue'
                    ? '0.44rem'
                    : '0.88rem',
              }"
              :key="q.ID"
            >
              <span
                class="title_type"
                :style="{
                  background:
                    q.type.indexOf('Tongue') > -1
                      ? 'linear-gradient(90deg, #FFC2A1, #FE714B)'
                      : ' linear-gradient(90deg, #53a0ff 0%, #1578f2 100%)',
                }"
                >{{ q.typeName }}</span
              >
              <span class="title_text" v-html="q.title"></span>
            </div>
            <div class="options" :key="q.ID + 'options'">
              <template v-if="q.type.indexOf('MultipleChoice') > -1">
                <van-checkbox-group v-model="q.q_res">
                  <van-checkbox
                    shape="square"
                    :class="{ operror: q.resMap[item.split('.')[0]] }"
                    :name="item.split('.')[0]"
                    v-for="(item, index) in q.options.split('_ZVING_')"
                    :key="index"
                    ><span v-html="item"></span
                  ></van-checkbox>
                </van-checkbox-group>
              </template>
              <template v-if="q.type.indexOf('SingleChoice') > -1">
                <van-radio-group v-model="q.q_res">
                  <van-radio
                    :name="item.split('.')[0]"
                    :class="{ operror: q.answer !== q.q_res }"
                    v-for="(item, index) in q.options.split('_ZVING_')"
                    :key="index"
                    ><span v-html="item"></span
                  ></van-radio>
                </van-radio-group>
              </template>
              <template v-if="q.type.indexOf('GroupOption') > -1">
                <div class="title_p_title" v-html="q.PTitle"></div>
                <van-radio-group v-model="q.q_res" class="groupOption">
                  <van-radio
                    :name="item.split('.')[0]"
                    :class="{ operror: q.answer !== q.q_res }"
                    v-for="(item, index) in q.options.split('_ZVING_')"
                    :key="index"
                    ><span v-html="item"></span
                  ></van-radio>
                </van-radio-group>
              </template>
              <template v-if="q.type.indexOf('GroupStem') > -1">
                <div class="title_p_title" v-html="q.PTitle"></div>
                <van-radio-group v-model="q.q_res">
                  <van-radio
                    :name="item.split('.')[0]"
                    :class="{ operror: q.answer !== q.q_res }"
                    v-for="(item, index) in q.options.split('_ZVING_')"
                    :key="index"
                    ><span v-html="item"></span
                  ></van-radio>
                </van-radio-group>
              </template>
              <template v-if="q.type.indexOf('CaseAnalysis') > -1">
                <div class="title_p_title" v-html="q.PTitle"></div>
                <van-checkbox-group v-model="q.q_res">
                  <van-checkbox
                    shape="square"
                    :class="{ operror: q.resMap[item.split('.')[0]] }"
                    :name="item.split('.')[0]"
                    v-for="(item, index) in q.options.split('_ZVING_')"
                    :key="index"
                    ><span v-html="item"></span
                  ></van-checkbox>
                </van-checkbox-group>
              </template>
              <template v-if="q.type.indexOf('Checking') > -1">
                <van-radio-group v-model="q.q_res">
                  <van-radio
                    :name="item.split('.')[1]"
                    :class="{ operror: q.answer !== q.q_res }"
                    v-for="(item, index) in q.options.split('_ZVING_')"
                    :key="index"
                    >{{ item.split('.')[2] }}</van-radio
                  >
                </van-radio-group>
              </template>
              <template v-if="q.type.indexOf('Completion') > -1">
                <van-field
                  class="tkt"
                  v-for="(item, index) in q.inputOptions"
                  :key="index"
                  v-model="item.value"
                  :placeholder="`请输入第${formatValue(index)}项答案`"
                />
              </template>
              <template v-if="q.type.indexOf('Answer') > -1">
                <van-field
                  class="jdt"
                  v-for="(item, index) in q.inputOptions"
                  type="textarea"
                  rows="6"
                  :key="index"
                  v-model="item.value"
                  :placeholder="`请输入第${formatValue(index)}项答案`"
                />
              </template>
              <template v-if="q.type.indexOf('Tongue') > -1">
                <van-row type="flex" justify="space-between" class="tongue score-box">
                  <van-col span="7">
                    <div class="score">
                      <div>
                        {{ averageScore }}
                      </div>
                    </div>
                  </van-col>
                  <van-col span="7" class="flex-center">
                    <div class="highest">
                      历史最高 <span>{{ max }}</span>
                    </div>
                  </van-col>
                  <van-col span="10" class="flex-center right" @click="tongueStatus.showRecordingList = true">
                    <div class="icon" v-for="item in flagList" :key="item.ID">
                      <img v-if="item.isPass === 1" src="../../../public/images/allCourse/answer/pic_qualified.png" />
                      <img v-else src="../../../public/images/allCourse/answer/icon_no.png" />
                    </div>
                  </van-col>
                </van-row>
              </template>
            </div>
          </template>
        </template>
        <div class="tongue" v-if="questionlist[activeIndex] && questionlist[activeIndex].type.indexOf('Tongue') > -1">
          <div class="correct answer-back">
            <div class="correcttext">标准答案</div>
            <div class="btomtext" v-html="highlightOutsideHashes(questionlist[activeIndex].answer || '')"></div>
          </div>
        </div>
        <div class="correct sup" v-else>
          <span class="line linesup"></span>
          <!-- <span class="correcttext">正确答案: {{ questionlist[activeIndex] && questionlist[activeIndex].answer }}</span> -->
          <span
            class="correcttext"
            v-if="questionlist[activeIndex] && questionlist[activeIndex].type.indexOf('Answer') > -1"
            >正确答案:
            <span v-html="questionlist[activeIndex].answer" class="answer-span"></span>
          </span>
          <span class="correcttext" v-else>正确答案: {{ questionlist[activeIndex] | formatValue }}</span>
        </div>
      </div>
      <van-row class="tongue" v-if="questionlist[activeIndex] && questionlist[activeIndex].type.indexOf('Tongue') > -1">
        <div class="analysis answer-back">
          <div class="correcttext">答案解析</div>
          <div
            class="btomtext"
            v-if="questionlist[activeIndex] && questionlist[activeIndex].explainInfo"
            v-html="questionlist[activeIndex] && questionlist[activeIndex].explainInfo"
          ></div>
          <div class="btomtext" v-else style="text-align: center">暂无分析</div>
        </div>
        <van-row type="flex" justify="space-around" class="bnt_answer">
          <van-col
            class="bnt-name"
            @click="listing"
            :class="{ 'template-btn--disabled': questionlist[activeIndex] && !questionlist[activeIndex].audioUrl }"
          >
            <img
              :class="{ 'animation-paused': !isPlayingTemplateAudio, 'rotate-playing-audio-btn': templateBtnReset }"
              src="../../../public/images/allCourse/answer/btn_listen.png"
              ref="rotate-btn"
            />
            <div class="name">听模板</div>
            <div style="opacity: 0">
              <audio loop class="audio" :src="questionlist[activeIndex].audioUrl"></audio>
            </div>
          </van-col>
          <van-col class="bnt-name" @click="tongueStatus.showRecordingList = true">
            <img src="../../../public/images/allCourse/answer/btn_sound.png" />
            <div class="name">回放</div>
          </van-col>
        </van-row>
      </van-row>
      <div class="analysis" v-else>
        <!-- <van-row class="analysisbox">
          <van-col span="8">
            <div class="analysisnum"><span>10</span>次</div>
            <div class="analysistext">答题次数</div>
          </van-col>
          <van-col span="8">
            <div class="analysisnum"><span>5</span>次</div>
            <div class="analysistext">正确次数</div>
          </van-col>
          <van-col span="8">
            <div class="analysisnum"><span>50</span>%</div>
            <div class="analysistext">正确率</div>
          </van-col>
        </van-row> -->
        <van-row class="analysisbtom">
          <div class="correct">
            <span class="line"></span>
            <span class="correcttext">答案分析:</span>
          </div>
          <div
            class="btomtext"
            v-if="questionlist[activeIndex] && questionlist[activeIndex].explainInfo"
            v-html="questionlist[activeIndex] && questionlist[activeIndex].explainInfo"
          ></div>
          <div class="btomtext" v-else style="text-align: center">暂无分析</div>
        </van-row>
      </div>

      <div class="boxbotm" :style="{ bottom: isiOS ? '0.8rem' : '0' }">
        <div class="iconbox">
          <span class="botmicon" @click="golist"><van-icon name="apps-o" /></span>
          <span>{{ activeIndex + 1 + '/' + questionlist.length }}</span>
        </div>
        <div class="changebtn">
          <van-button class="info-btn" plain round hairline size="large" type="info" @click="preQues"
            >上一题</van-button
          >
          <van-button class="info-btn" round hairline size="large" type="info" @click="nextQues">下一题</van-button>
        </div>
      </div>
      <!-- 回放音频列表 -->
      <van-popup
        position="bottom"
        :value="tongueStatus.showRecordingList"
        :overlay-style="{ background: 'rgba(0, 0, 0, 0.4)' }"
        round
      >
        <div class="popup-audio-list__wrap">
          <div class="title">
            <span>回放（{{ oraldata.length }}条）</span>
            <span class="close-btn" @click="handleCloseBtnClick">关闭</span>
          </div>
          <van-empty image="error" description="暂无已录音记录" v-if="oraldata.length <= 0" />
          <div class="list-scroll__wrap" v-else>
            <van-list>
              <van-cell
                class="list-s"
                :class="activeSourceIndex === index && 'is-active'"
                v-for="(recItem, index) in oraldata"
                :key="index.ID"
              >
                <div
                  class="list-item"
                  :class="{ 'list-item--failed': recItem.isPass !== 1 }"
                  @click="handlePlayAudio(recItem, index)"
                >
                  <div class="col-left">
                    <img
                      class="icon"
                      v-if="recItem.isPass === 1"
                      src="../../../public/images/allCourse/answer/pic_qualified.png"
                    />
                    <img class="icon" v-else src="../../../public/images/allCourse/answer/icon_no.png" />
                    <span class="name">{{ `口语${Number(paperType) === 1 ? '训练' : '考试'}` + recItem.addTime }}</span>
                    <span class="time"
                      ><van-count-down
                        ref="countDown"
                        millisecond
                        v-if="Number(recItem.isComplete) === 1"
                        :time="formatMMss(recItem.lengthHMS)"
                        :auto-start="false"
                        format="mm:ss"
                      />
                      <span v-else>计算中...</span>
                    </span>
                  </div>
                  <div class="col-right score">
                    <div class="score-txt">{{ recItem.synthesize }}</div>
                    <div class="score-img"></div>
                  </div>
                  <div class="listting" style="opacity: 0">
                    <audio class="audio" :src="recItem.playURL" ref="middle" @ended="handleAudioEnded"></audio>
                  </div>
                </div>
              </van-cell>
            </van-list>
          </div>
          <div class="close-btn__wrap"></div>
        </div>
      </van-popup>
    </div>
    <examination-list
      :questionlist="questionlist"
      @back="isShow = false"
      v-if="isShow"
      @setActiveIndex="setActiveIndex"
    ></examination-list>
  </div>
</template>

<script>
import ExaminationList from './ExaminationList.vue'
import Aliplayer from './AliPlayer.vue'

export default {
  name: 'wrongtopic',
  components: {
    'examination-list': ExaminationList,
    'ali-player': Aliplayer,
  },
  data() {
    return {
      activeIndex: 0,
      pagename: '',
      isShow: false,
      questionlist: [],
      oraldata: [],
      flagList: [],
      max: null,
      isiOS: false,
      tongueMediaSrcData: {
        videoUrl: '',
      },
      tongueStatus: {
        showRecordingList: false,
      },
      activeSourceIndex: -1,
      isPlayingTemplateAudio: false,
      templateBtnReset: true,
    }
  },
  computed: {
    isTongue() {
      return this.questionlist[this.activeIndex] && this.questionlist[this.activeIndex].type.indexOf('Tongue') > -1
    },
    paperType() {
      return this.$route.query.paperType
    },
    averageScore() {
      let res = 0
      let count = 0
      this.oraldata.forEach((item) => {
        if (Number(item.isPass) === 1) {
          res += item.synthesize
          count++
        }
      })
      if (res <= 0) return 0
      return Math.round(res / count)
    },
  },
  filters: {
    formatValue(item) {
      if (!item) {
        return ''
      }
      if (item.type.indexOf('Checking') > -1) {
        let arr = item.options.split('_ZVING_')
        for (let i = 0; i < arr.length; i++) {
          if (arr[i].indexOf(item.answer) > -1) {
            return arr[i].split('.')[2]
          }
        }
      }
      if (item.type.indexOf('Completion') > -1) {
        return item.answer.split('_ZVING_').join(',')
      }
      return item.answer
    },
  },
  created() {
    this.initPage()
    this.setShare()
    this.isiOS = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
  },
  watch: {
    activeIndex: {
      handler(newVal) {
        // init
        this.isPlayingTemplateAudio = false
        this.tongueMediaSrcData = this.$options.data().tongueMediaSrcData
        const currentQuestion = this.questionlist[newVal]
        if (!currentQuestion) return
        if (currentQuestion.type.indexOf('Tongue') > -1) {
          this.initTemplateAudio()
          this.isPlayingTemplateAudio = false
          this.templateBtnReset = false
          window.setTimeout(() => {
            this.templateBtnReset = true
          }, 1)
        }
        this.tongue(currentQuestion.ID)
        if (this.questionlist[newVal].videoAssertID) this.getTongueVideoSrcByID(currentQuestion.videoAssertID)
      },
    },
    'tongueStatus.showRecordingList'(state) {
      if (!state) {
        if (this.activeSourceIndex < 0) return
        this.$nextTick(() => {
          this.$refs.middle && this.$refs.middle[this.activeSourceIndex]?.pause()
          this.$refs.countDown && this.$refs.countDown[this.activeSourceIndex]?.reset()
          this.activeSourceIndex = -1
        })
      } else {
        this.$nextTick(() => {
          this.pauseVideo()
          document.querySelector('audio').pause()
          this.isPlayingTemplateAudio = false
        })
      }
    },
  },
  methods: {
    pauseVideo() {
      // 暂停视频
      this.$nextTick(() => {
        const elAliPlayer = this.$refs['ali-player']
        if (elAliPlayer && elAliPlayer.aliPlayer) {
          elAliPlayer.aliPlayer.pause()
        }
      })
    },
    formatValue(value) {
      let num = ['一', '二', '三', '四', '五', '六', '七', '八', '九']
      return num[value] || '一'
    },
    // 分享
    setShare() {
      const shareInfo = {
        disabled: true,
      }
      // mixins
      this.wechatShare(shareInfo)
    },
    initTemplateAudio() {
      let music = document.querySelector('audio')
      if (music) {
        music.pause()
        this.isPlayingTemplateAudio = false
      }
    },
    initPage() {
      let query = `paperID=${this.$route.params.id}`
      if (this.$route.query.examID) {
        query += `&examID=${this.$route.query.examID}`
      }
      this.$axios.get(`/tk/front/paper/detail?${query}`).then((res) => {
        if (res.data.status === 1) {
          this.pagename = res.data.data.paperName + '(答案分析)'
          let data = res.data.data.questionList
          data = data.map((item) => {
            if (item.type.indexOf('Completion') > -1) {
              let arr = []
              for (let i = 0; i < item.options; i++) {
                let obj = {
                  value: item.respond ? item.respond.split('_ZVING_')[i] : '',
                }
                arr.push(obj)
              }
              item.inputOptions = arr
            } else if (item.type.indexOf('Answer') > -1) {
              let arr = []
              let obj = {
                value: item.respond ? item.respond : '',
              }
              arr.push(obj)
              item.inputOptions = arr
            } else if (item.type.indexOf('MultipleChoice') > -1 || item.type.indexOf('CaseAnalysis') > -1) {
              let answer = item.answer ? item.answer.split('') : []
              let respond = item.respond ? item.respond.split('') : []
              let resMap = {}
              for (let k = 0; k < respond.length; k++) {
                resMap[respond[k]] = answer.indexOf(respond[k]) === -1
              }
              item.q_res = respond
              item.resMap = resMap
            } else if (item.type.indexOf('Checking') > -1) {
              item.options = 'A.Y.正确_ZVING_B.N.错误'
              item.q_res = item.respond ? item.respond : ''
            } else {
              item.q_res = item.respond ? item.respond : ''
            }
            return item
          })
          this.questionlist = data
          this.tongue(data[this.activeIndex].ID)
          if (data[this.activeIndex].videoAssertID) this.getTongueVideoSrcByID(data[this.activeIndex].videoAssertID)
        } else {
          this.$Toast.fail(res.data.message)
        }
      })
    },
    tongue(id) {
      if (this.questionlist[this.activeIndex].type.indexOf('Tongue') > -1) {
        this.$Toast.loading({
          duration: 0,
          message: '正在加载口语记录...',
        })
      }
      this.$axios
        .get(`/tk/front/tongue/answer/${id}/details`, {
          params: {
            paperID: this.$route.params.id,
          },
        })
        .then((res) => {
          this.max = null
          if (res.data.status === 1) {
            this.oraldata = res.data.data
            if (this.oraldata[0]) {
              this.max = Number(this.oraldata[0].synthesize) || 0
              this.oraldata.forEach((e, i) => {
                if (Number(this.oraldata[i].synthesize) > this.max) {
                  this.max = this.oraldata[i].synthesize
                }
              })
            }
            this.flagList = res.data.data.slice(0, 4)
          }
        })
        .finally(() => {
          this.$Toast.clear()
        })
    },
    // 请求口语题的视频资源
    getTongueVideoSrcByID(ID) {
      this.$axios.get(`/tk/front/tongue/answer/assertplayurl?assertID=${ID}`).then(({ data }) => {
        if (Number(data.status) !== 1) return this.$Toast.fail('视频资源请求失败，请重试')
        this.tongueMediaSrcData.videoUrl = data.message
      })
    },
    golist() {
      this.isShow = true
    },
    listing() {
      var music = document.querySelector('audio')
      if (!this.questionlist[this.activeIndex].audioUrl) {
        this.$Toast.fail('暂无音频资源')
        return
      }
      this.pauseVideo()
      if (music.paused) {
        music.play()
        this.isPlayingTemplateAudio = true
      } else {
        music.pause()
        this.isPlayingTemplateAudio = false
      }
    },
    // 回放
    handlePlayAudio(item, index) {
      const playying = this.activeSourceIndex === index
      this.$nextTick(() => {
        if (!this.$refs.middle) return this.$Toast.fail('回放音频暂未加载好')

        const middle = this.$refs.middle[index]
        if (this.oraldata[index].isPass === 1) {
          this.$refs.middle.forEach((middle, i) => {
            if (i !== index) {
              middle.pause()
              middle.currentTime = 0
              if (this.$refs.countDown) {
                this.$refs.countDown[i] && this.$refs.countDown[i].reset()
              }
            }
          })
          if (playying) {
            this.activeSourceIndex = -1
            middle.pause()
            this.$nextTick(() => {
              if (this.$refs.countDown) {
                this.$refs.countDown[index] && this.$refs.countDown[index].pause()
              }
            })
          } else {
            this.pauseVideo() // 暂停视频
            this.activeSourceIndex = index
            middle.play()
            this.$nextTick(() => {
              if (this.$refs.countDown) {
                this.$refs.countDown[index] && this.$refs.countDown[index].start()
              }
            })
          }
        } else {
          this.$toast.fail({
            message: '该录音不可回放',
          })
          middle.pause()
        }
      })
    },
    // 时间格式
    formatMMss(hhmmss) {
      const splitTime = hhmmss.split(':')
      const secs = Number(splitTime[0]) * 60 * 60 + Number(splitTime[1]) * 60 + Number(splitTime[2])
      return secs * 1000
    },
    // 上一题
    preQues() {
      if (this.activeIndex > 0) {
        this.activeIndex = this.activeIndex - 1
      } else {
        this.$Toast.fail('已经是第一题了')
      }
    },
    // 下一题
    nextQues() {
      if (this.activeIndex < this.questionlist.length - 1) {
        this.activeIndex = this.activeIndex + 1
      } else {
        this.$Toast.fail('已经是最后一题了')
      }
    },
    setActiveIndex(index) {
      this.activeIndex = index
      this.isShow = false
    },
    mergeArray(arr1, arr2) {
      let _arr = new Array()
      for (let i = 0; i < arr1.length; i++) {
        _arr.push(arr1[i])
      }
      for (let i = 0; i < arr2.length; i++) {
        let flag = true
        for (let j = 0; j < arr1.length; j++) {
          if (arr2[i] === arr1[j]) {
            flag = false
            break
          }
        }
        if (flag) {
          _arr.push(arr2[i])
        }
      }
      return _arr
    },
    handleAudioEnded(e) {
      console.log('e: ', e)
      e.target.currentTime = 0
      this.$nextTick(() => {
        if (this.$refs.countDown) {
          this.$refs.countDown.forEach((item) => {
            item && item.reset()
          })
        }
        this.activeSourceIndex = -1
      })
    },
    handleCloseBtnClick() {
      this.tongueStatus.showRecordingList = false
    },
    highlightOutsideHashes(str = '') {
      str = str.replaceAll('\n', '<br/>')
      // 正则表达式匹配 #...# 之间的内容，包括单个 #，以及它们之间的所有内容
      let matches = []
      let result = str.replace(/#[^#]*#/g, (match) => {
        // 将匹配到的 #...# 内容保存起来，稍后替换回去
        const placeholder = `__HASH_${matches.length}__`
        matches.push(match)
        return placeholder
      })

      // 使用正则表达式来匹配 __HASH_\d__ 模式，并捕获其间的任意字符（包括换行符）
      const pattern = /(__HASH_\d+__)|([\s\S]*?(?=__HASH_\d+__|$))/g
      result = result.replace(pattern, (match, p1, p2) => {
        if (p1) {
          // 如果是 __HASH_\d__，直接返回原字符串
          return p1
        } else if (p2) {
          // 否则，将非 __HASH_\d__ 内容用 <span> 标签包裹
          return `<span class="green-text">${p2}</span>`
        }
        return match // 默认情况下返回原始匹配项
      })

      // 将之前保存的 #...# 内容替换回原位，并去掉#
      matches.forEach((match, index) => {
        const placeholder = `__HASH_${index}__`
        result = result.replace(placeholder, match.replaceAll('#', ''))
      })
      return result
    },
  },
}
</script>
<style scoped>
.examinationpaper >>> img {
  max-width: 100%;
  height: auto;
}
.examinationpaper >>> .groupOption.van-radio-group {
  display: flex;
  justify-content: space-around;
}
.testbox .options >>> .tkt.van-cell::after {
  right: 0;
  left: 0;
}
.testbox .options >>> .jdt.van-cell::after {
  right: 0;
  left: 0;
}
</style>
<style lang="less" scoped>
.wrongtopic {
  min-height: 100vh;
  .paperbox {
    display: flex;
    flex-direction: column;
    height: 84vh;
    overflow-y: auto;
  }
  .paperbox--tongue-ios {
    height: calc(100vh - 1.33rem - 0.8rem - 1.22rem);
  }
  .paperbox--tongue {
    height: calc(100vh - 1.33rem - 1.22rem);
  }
  .rotate-playing-audio-btn {
    animation: rotate-playing 10s linear infinite;
  }
  .animation-paused {
    animation-play-state: paused;
  }
  @keyframes rotate-playing {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  .template-btn--disabled {
    filter: saturate(0%);
  }
  .correct {
    .line {
      display: inline-block;
      width: 0.18rem;
      height: 0.43rem;
      background-color: #1578f2;
      border-radius: 0.08rem;
      vertical-align: middle;
      margin-right: 0.29rem;
    }
    .correcttext {
      font-family: PingFang-SC-Medium;
      font-size: 0.4rem;
      font-stretch: normal;
      letter-spacing: 0rem;
      color: #222222;
    }
    .correcttext .answer-span /deep/ * {
      display: inline;
    }
  }
  .sup {
    display: flex;
    align-items: center;
    .linesup {
      flex: 0 0 0.18rem;
    }
  }

  .testbox {
    background-color: #fff;
    padding: 0.43rem 0.33rem;
    .title {
      margin-bottom: 0.88rem;
      .title_type {
        font-size: 0.28rem;
        padding: 0.11rem 0.2rem;
        color: #fff;
        background-image: linear-gradient(90deg, #53a0ff 0%, #1578f2 100%);
        border-radius: 0.27rem 0rem 0.27rem 0rem;
        margin-right: 0.2rem;
      }
      .title_text {
        font-family: PingFang-SC-Medium;
        font-size: 0.4rem;
        font-weight: normal;
        font-stretch: normal;
        line-height: 0.65rem;
        letter-spacing: 0rem;
        color: #333333;
        /deep/ img {
          max-width: 100%;
          max-height: 100%;
        }
        /deep/ p {
          display: inline;
          margin: 0;
        }
      }
    }
    .options {
      .van-radio,
      .van-checkbox {
        margin-bottom: 0.66rem;
        font-family: PingFang-SC-Regular;
        font-size: 0.4rem;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0rem;
        color: #333333;
        pointer-events: none; // css禁止点击事件
      }
      /deep/ .van-cell {
        padding-left: 0;
        padding-right: 0;
      }
      .van-cell:last-child {
        margin-bottom: 0.66rem;
      }
      .van-cell--borderless::after,
      .van-cell:last-child::after {
        display: block;
      }
      /deep/ input {
        pointer-events: none;
      }
      .operror {
        /deep/ .van-checkbox__icon--checked {
          .van-icon {
            background-color: red;
            border-color: red;
            line-height: 1.24;
          }
          .van-icon-success::before {
            content: '\F042';
          }
        }
        /deep/ .van-radio__icon--checked {
          .van-icon {
            background-color: red;
            border-color: red;
            line-height: 1.24;
          }
          .van-icon-success::before {
            content: '\F042';
          }
        }
      }
    }
    .title_p_title {
      font-family: PingFang-SC-Medium;
      font-size: 0.4rem;
      font-weight: normal;
      font-stretch: normal;
      line-height: 0.65rem;
      letter-spacing: 0rem;
      color: #333333;
      margin-bottom: 0.88rem;
      /deep/ p {
        display: inline-block;
      }
    }
  }
  .analysis {
    border-top: 0.29rem solid #f8f8f8;
    padding: 0rem 0.33rem;
    background-color: #fff;
    margin-bottom: 1rem;
    padding-bottom: 0.5rem;

    .analysisbox {
      text-align: center;
      padding: 0.54rem 0;
      .van-col {
        position: relative;
      }
      .van-col::after {
        content: ' ';
        position: absolute;
        top: 25%;
        right: 0;
        width: 1px;
        height: 0.8rem;
        background-color: #e5e5e5;
      }
      .van-col:last-child::after {
        content: '';
        width: 0;
      }
      .analysisnum {
        font-size: 0.27rem;
        font-family: PingFang-SC-Heavy;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0rem;
        color: #111111;
        margin-bottom: 0.3rem;
        span {
          font-size: 0.45rem;
        }
      }
      .analysistext {
        font-family: PingFang-SC-Regular;
        font-size: 0.27rem;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0rem;
        color: #333333;
      }
    }
    .analysisbtom {
      .btomtext {
        padding-top: 0.33rem;
        font-family: PingFang-SC-Medium;
        font-size: 0.3rem;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0rem;
        color: #444444;
        /deep/ img {
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
  }
  .changebtn {
    display: flex;
    justify-content: space-around;
    text-align: center;
    align-items: center;
    font-family: PingFang-SC-Medium;
    font-size: 0.31rem;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0rem;
    color: #1578f2;
    .info-btn {
      //   width: 30%;
      height: 1rem;
      padding: 0 0.33rem;
      margin-right: 0.33rem;
      width: 2.2rem;
      .van-button__text {
        font-size: 0.31rem;
      }
    }
  }
  .boxbotm {
    background-color: #fff;
    border-top: 1px solid #f2f2f2;
    position: fixed;
    bottom: 0;
    height: 1.33rem;
    line-height: 1.33rem;
    width: 100%;
    font-family: PingFang-SC-Medium;
    font-size: 0.4rem;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0rem;
    color: #666666;
    display: flex;
    justify-content: space-between;
    .iconbox {
      margin-left: 0.33rem;
    }
    .botmicon {
      font-size: 0.5rem;
      vertical-align: sub;
      margin-right: 0.25rem;
    }
  }
  .tongue {
    &.score-box {
      height: 1.33rem;
      background: #fcf8e8;
      border-radius: 0.21rem;
      position: relative;
      padding: 0 0.29rem 0 0.32rem;
      .score {
        background: url('../../../public//images/allCourse//answer/pic_score.png') no-repeat;
        width: 2.13rem;
        height: 1.67rem;
        background-size: 100%;
        font-size: 0.48rem;
        font-family: PingFang SC;
        font-weight: bold;
        color: #f35f06;
        line-height: 1.2rem;
        text-align: center;
        position: absolute;
        top: -0.14rem;
      }
      .flex-center {
        display: flex;
        align-items: center;
        &.right {
          justify-content: end;
        }
      }
      .highest {
        font-size: 0.32rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: #dbce7a;
        span {
          font-size: 0.43rem;
          margin-left: 0.2rem;
        }
      }
      .icon {
        float: right;
        img {
          width: 0.92rem;
          height: 0.71rem;
          // &:not(:last-child) {
          //   margin-right: 0.13rem;
          // }
        }
      }
    }
    .answer-back {
      min-height: 2rem;
      background: #f8f8f8;
      border-radius: 0.08rem;
      padding: 0.4rem 0.33rem 0.76rem 0.33rem;
      .correcttext {
        font-size: 0.37rem;
        font-family: PingFang SC;
        font-weight: bold;
        color: #ff5223;
        margin-bottom: 0.31rem;
      }
      .btomtext {
        font-size: 0.4rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 0.56rem;
      }
      &.correct {
        margin-top: 0.44rem;
      }
      &.analysis {
        margin: 0 0.33rem;
        border-top: none;
      }
    }
    .bnt_answer {
      margin-top: 0.83rem;
      margin-bottom: 0.52rem;
      .bnt-name {
        max-width: 1.44rem;
        text-align: center;
        cursor: pointer;
        img {
          width: 1.44rem;
          height: 1.44rem;
        }
        .name {
          font-size: 0.37rem;
          font-family: PingFang SC;
          font-weight: 500;
          color: #fe714b;
          margin-top: 0.31rem;
        }
      }
    }
  }
  // 口语音频列表
  .popup-audio-list__wrap {
    height: 72vh;
    padding: 0.4267rem 0;
    .list-scroll__wrap {
      height: 55vh;
      padding: 0 0.4rem;

      overflow-y: auto;
    }
    .close-btn__wrap {
      margin-top: 8vh;
      text-align: center;
      .close-btn {
        font-size: 0.4267rem;
        color: #808080;
        cursor: pointer;
      }
    }
    .title {
      display: flex;
      justify-content: space-between;
      padding: 0 0.4267rem;
      margin-bottom: 0.4267rem;
      font-weight: 500;
      font-size: 0.4267rem;
      color: #0e0e0e;
    }
    .list-s {
      &.is-active {
        .list-item {
          .name {
            color: #fe714b;
          }

          .score {
            .score-img {
              display: block;
            }
            .score-txt {
              display: none;
            }
          }
        }
      }
    }
    .list-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.2667rem 0;
      cursor: pointer;
      .icon {
        width: 0.6933rem;
        height: 0.5333rem;
        margin-right: 0.2133rem;
        vertical-align: middle;
      }
      .name {
        width: 43vw;
        margin-right: 0.3333rem;
        font-weight: 500;
        font-size: 0.4rem;
        color: #333333;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        &.on {
          color: #fe714b;
        }
      }
      .time,
      .van-count-down {
        font-size: 0.32rem;
        color: #a2a2a2;
      }
      .score {
        font-size: 0.4rem;
        color: #66cb55;
        .score-img {
          background: url('../../../public/images/allCourse/paper/playing-gif.gif') no-repeat;
          width: 0.4rem;
          height: 0.35rem;
          background-size: 100%;
          display: none;
        }
      }
      span {
        display: inline-block;
        vertical-align: middle;
      }
    }
    .list-item--playing {
      .name {
        color: #fe714b;
      }
    }
    .list-item--failed {
      .icon {
        filter: saturate(0%);
      }
      .name {
        color: #a6a6a6;
      }
      .time,
      .van-count-down {
        color: #c3c3c3;
      }
      .score {
        color: #cecece;
      }
    }
  }
}
</style>
